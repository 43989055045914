<template>
  <div
    class="wrapper"
    :style="{
      'background-image': `url(${pictureUrl})`,
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      'background-size': 'cover',
    }"
  >
    <div class="top-info dsp-mobile" id="top-info">
      <div class="main clearfix">
        <div class="txt-center">
          <button
            class="navbar-toggle collapsed fl"
            aria-label="navbar"
            type="button"
            onclick="showDrawerMenu()"
          >
            <span class="icon-bar"></span> <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a
            class="txtlogo logo-mobile"
            href="https://minhngocstar.com/"
            rel="self"
            ><img src="https://minhngocstar.com/assets/images/logo.png"
          /></a>
        </div>
      </div>
    </div>
    <div class="main relative">
      <div class="header">
        <div class="top-info dsp-desktop">
          <div class="main clearfix">
            <div class="fr">
              <img src="@/assets/images/Hanoi-Visa-banner.gif" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main breaking-news dsp-desktop">
      <div class="bn-box">
        <div class="bn-title">Thông báo</div>
        <div class="bn-text">
          <a href="#" class="waitting"
            >Đang chờ xổ số Đồng Tháp lúc
            {{ formatTime(this.nextday.datetime) }}':
            {{ formatDate(this.nextday.datetime) }}. Còn {{ countdown }} nữa!</a
          >
        </div>
        <img
          src="@/assets/images/bn-arrows.png"
          style="opacity: 0.2; margin-right: 10px; cursor: pointer;"
        />
      </div>
    </div>
    <div class="content">
      <div class="main clearfix">
        <div class="col-l">
          <div>
            <div>
              <div>
                <div class="my-result one-city--xosotv">
                  <table data-title="" class="kqmb extendable">
                    <tbody>
                      <tr class="madb">
                        <td colspan="12">
                          <div
                            class="tit-mien tit-mienx clearfix"
                            style="background-image: url('https://minhngocstar.com/assets/images/bar2.png'); background-position: center top; background-size: 520px 40px; background-repeat: no-repeat; background-attachment: initial; background-origin: initial; background-clip: initial; background-color: rgb(255, 255, 255) !important;"
                          >
                            <h2>
                              Hà Nội ngôi sao:
                              <span class="current-date">{{
                                formatDate(this.first.datetime)
                              }}</span>
                            </h2>
                          </div>
                        </td>
                      </tr>
                      <tr class="db">
                        <td class="txt-giai" style="color: inherit;">
                          Đặc biệt
                        </td>
                        <td colspan="12" class="number">
                          <span>
                            <b v-if="!loading[0] || this.first.five">
                              <span id="dice1">{{
                                this.first &&
                                  this.first.five &&
                                  this.first.five.charAt(0)
                              }}</span>
                              <span id="dice2">{{
                                this.first &&
                                  this.first.five &&
                                  this.first.five.charAt(1)
                              }}</span>
                              <span id="dice3">{{
                                this.first &&
                                  this.first.five &&
                                  this.first.five.charAt(2)
                              }}</span>
                              <span id="dice4">{{
                                this.first &&
                                  this.first.five &&
                                  this.first.five.charAt(3)
                              }}</span>
                              <span id="dice5">{{
                                this.first &&
                                  this.first.five &&
                                  this.first.five.charAt(4)
                              }}</span>
                              <img
                                src="@/assets/images/Spinner.gif"
                                width="40"
                                v-if="
                                  this.first &&
                                    this.first.five == ''
                                "
                              />
                            </b>
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="50"
                              v-if="loading[0]"
                            />
                            
                          </span>
                        </td>
                      </tr>
                      <tr class="bg_f6">
                        <td class="txt-giai">Giải nhất</td>
                        <td colspan="12" class="number">
                          <span>
                            <b v-if="!loading[1] || this.first.five2">
                              <span id="dice6">{{
                                this.first &&
                                  this.first.five2 &&
                                  this.first.five2.charAt(0)
                              }}</span>
                              <span id="dice7">{{
                                this.first &&
                                  this.first.five2 &&
                                  this.first.five2.charAt(1)
                              }}</span>
                              <span id="dice8">{{
                                this.first &&
                                  this.first.five2 &&
                                  this.first.five2.charAt(2)
                              }}</span>
                              <span id="dice9">{{
                                this.first &&
                                  this.first.five2 &&
                                  this.first.five2.charAt(3)
                              }}</span>
                              <span id="dice10">{{
                                this.first &&
                                  this.first.five2 &&
                                  this.first.five2.charAt(4)
                              }}</span>
                              <img
                                src="@/assets/images/Spinner.gif"
                                width="40"
                                v-if="
                                  this.first &&
                                    this.first.five2 == ''
                                "
                              />
                            </b>
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[1]"
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="txt-giai">Giải nhì</td>
                        <td colspan="6" class="number">
                          <span>
                            <b v-if="!loading[2]">
                              <span id="dice11">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[0]
                              }}</span>
                            </b>
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[2]"
                          /></span>
                        </td>
                        <td colspan="6" class="number">
                          <span>
                            <b v-if="!loading[3]">
                              <span id="dice12">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[1]
                              }}</span>
                            </b>
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[3]"
                          /></span>
                        </td>
                      </tr>
                      <tr class="giai3 bg_ef">
                        <td rowspan="2" class="txt-giai">Giải ba</td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[4]"
                              ><span id="dice13">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[2]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[4]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[5]"
                              ><span id="dice14">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[3]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[5]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[6]"
                              ><span id="dice15">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[4]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[6]"
                          /></span>
                        </td>
                      </tr>
                      <tr class="bg_ef">
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[7]"
                              ><span id="dice16">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[5]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[7]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[8]"
                              ><span id="dice17">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[6]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[8]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[9]"
                              ><span id="dice18">{{
                                this.first &&
                                  this.first.fivearray &&
                                  this.first.fivearray[7]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="40"
                              v-if="loading[9]"
                          /></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="txt-giai">Giải tư</td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[10]"
                              ><span id="dice19">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[0]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[10]"
                          /></span>
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[11]"
                              ><span id="dice20">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[1]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[11]"
                          /></span>
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[12]"
                              ><span id="dice21">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[2]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[12]"
                          /></span>
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[13]"
                              ><span id="dice22">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[3]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[13]"
                          /></span>
                        </td>
                      </tr>
                      <tr class="giai5 bg_ef">
                        <td rowspan="2" class="txt-giai">Giải năm</td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[14]"
                              ><span id="dice23">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[4]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[14]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[15]"
                              ><span id="dice24">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[5]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[15]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[16]"
                              ><span id="dice25">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[6]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[16]"
                          /></span>
                        </td>
                      </tr>
                      <tr class="bg_ef">
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[17]"
                              ><span id="dice26">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[7]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[17]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[18]"
                              ><span id="dice27">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[8]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[18]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[19]"
                              ><span id="dice28">{{
                                this.first &&
                                  this.first.fourarray &&
                                  this.first.fourarray[9]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="35"
                              v-if="loading[19]"
                          /></span>
                        </td>
                      </tr>
                      <tr>
                        <td class="txt-giai">Giải sáu</td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[20]"
                              ><span id="dice29">{{
                                this.first &&
                                  this.first.threearray &&
                                  this.first.threearray[0]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[20]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[21]"
                              ><span id="dice30">{{
                                this.first &&
                                  this.first.threearray &&
                                  this.first.threearray[1]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[21]"
                          /></span>
                        </td>
                        <td colspan="4" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[22]"
                              ><span id="dice31">{{
                                this.first &&
                                  this.first.threearray &&
                                  this.first.threearray[2]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[22]"
                          /></span>
                        </td>
                      </tr>
                      <tr class="db bg_ef">
                        <td class="txt-giai" style="color: inherit;">
                          Giải bảy
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[23]"
                              ><span id="dice32">{{
                                this.first &&
                                  this.first.twoarry &&
                                  this.first.twoarry[0]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[23]"
                          /></span>
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[24]"
                              ><span id="dice33">{{
                                this.first &&
                                  this.first.twoarry &&
                                  this.first.twoarry[1]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[24]"
                          /></span>
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[25]"
                              ><span id="dice34">{{
                                this.first &&
                                  this.first.twoarry &&
                                  this.first.twoarry[2]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[25]"
                          /></span>
                        </td>
                        <td colspan="3" class="number">
                          <span>
                            <!---->
                            <!---->
                            <!----><b v-if="!loading[26]"
                              ><span id="dice35">{{
                                this.first &&
                                  this.first.twoarry &&
                                  this.first.twoarry[3]
                              }}</span></b
                            >
                            <img
                              src="@/assets/images/Spinner.gif"
                              width="38"
                              v-if="loading[26]"
                          /></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div id="hottoday">
                <table
                  width="100%"
                  border="0"
                  cellpadding="3"
                  cellspacing="0"
                  class="border"
                  style="background-color: #FFF;margin-top: 10px;"
                >
                  <tbody>
                    <tr>
                      <td>
                        <strong
                          >Lịch Xổ Số
                          {{ formatDate(this.first.datetime) }}</strong
                        >
                      </td>
                      <td colspan="2" align="center">
                        <strong>Thống kê tần suất xổ số</strong>
                      </td>
                      <td align="center"><strong>Gan cực đại</strong></td>
                      <td align="center"><strong>Thống kê Loto</strong></td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_mien"
                          href="/xo-so-mien-nam.html"
                          title="Xổ Số Miền Nam"
                          ><strong>Xổ Số Miền Nam</strong></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-mien.html?mien=1&amp;lanquay=5&amp;tracuu=1&amp;dai1=1&amp;dai2=2"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Miền Nam"
                          >Tần suất Miền Nam</a
                        >
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-nam"
                          title="Thống kê tần suất chi tiết xổ số Miền Nam"
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-mien.html?mien=1"
                          title="Thống kê Gan Cực Đại Xổ Số Miền Nam"
                          >GCĐ Miền Nam</a
                        >
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-mien.html?mien=1&amp;dai1=1&amp;dai2=2"
                          title="Thống Kê lô các cặp số của Xổ Số Miền Nam"
                          >Xem Loto Miền Nam</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_tinh10"
                          href="/xo-so-mien-nam/dong-nai.html"
                          title="Xổ Số Đồng Nai"
                          ><span class="icon finnish">Xổ Số Đồng Nai</span></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=10&amp;lanquay=5&amp;tracuu=1"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Đồng Nai"
                          >Tần suất Đồng Nai
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=dong-nai"
                          title="Thống kê tần suất chi tiết xổ số  Đồng Nai "
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=10"
                          title="Thống kê Gan Cực Đại Xổ Số Đồng Nai "
                          >GCĐ Đồng Nai
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-tinh.html?tinh=10"
                          title="Thống Kê lô các cặp số của Xổ Số Đồng Nai"
                          >Xem Loto Đồng Nai
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_tinh11"
                          href="/xo-so-mien-nam/can-tho.html"
                          title="Xổ Số Cần Thơ"
                          ><span class="icon finnish">Xổ Số Cần Thơ</span></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=11&amp;lanquay=5&amp;tracuu=1"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Cần Thơ"
                          >Tần suất Cần Thơ
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=can-tho"
                          title="Thống kê tần suất chi tiết xổ số  Cần Thơ "
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=11"
                          title="Thống kê Gan Cực Đại Xổ Số Cần Thơ "
                          >GCĐ Cần Thơ
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-tinh.html?tinh=11"
                          title="Thống Kê lô các cặp số của Xổ Số Cần Thơ"
                          >Xem Loto Cần Thơ
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_tinh12"
                          href="/xo-so-mien-nam/soc-trang.html"
                          title="Xổ Số Sóc Trăng"
                          ><span class="icon finnish">Xổ Số Sóc Trăng</span></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=12&amp;lanquay=5&amp;tracuu=1"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Sóc Trăng"
                          >Tần suất Sóc Trăng
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=soc-trang"
                          title="Thống kê tần suất chi tiết xổ số  Sóc Trăng "
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=12"
                          title="Thống kê Gan Cực Đại Xổ Số Sóc Trăng "
                          >GCĐ Sóc Trăng
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-tinh.html?tinh=12"
                          title="Thống Kê lô các cặp số của Xổ Số Sóc Trăng"
                          >Xem Loto Sóc Trăng
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_mien"
                          href="/xo-so-mien-bac.html"
                          title="Xổ Số Miền Bắc"
                          ><strong>Xổ Số Miền Bắc</strong></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-mien.html?mien=2&amp;lanquay=5&amp;tracuu=1&amp;dai1=1&amp;dai2=2"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Miền Bắc"
                          >Tần suất Miền Bắc</a
                        >
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-bac"
                          title="Thống kê tần suất chi tiết xổ số Miền Bắc"
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-mien.html?mien=2"
                          title="Thống kê Gan Cực Đại Xổ Số Miền Bắc"
                          >GCĐ Miền Bắc</a
                        >
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-mien.html?mien=2&amp;dai1=1&amp;dai2=2"
                          title="Thống Kê lô các cặp số của Xổ Số Miền Bắc"
                          >Xem Loto Miền Bắc</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_tinh48"
                          href="/xo-so-mien-bac/bac-ninh.html"
                          title="Xổ Số Bắc Ninh"
                          ><span class="icon finnish">Xổ Số Bắc Ninh</span></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=48&amp;lanquay=5&amp;tracuu=1"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Bắc Ninh"
                          >Tần suất Bắc Ninh
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=bac-ninh"
                          title="Thống kê tần suất chi tiết xổ số  Bắc Ninh "
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=48"
                          title="Thống kê Gan Cực Đại Xổ Số Bắc Ninh "
                          >GCĐ Bắc Ninh
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-tinh.html?tinh=48"
                          title="Thống Kê lô các cặp số của Xổ Số Bắc Ninh"
                          >Xem Loto Bắc Ninh
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_mien"
                          href="/xo-so-mien-trung.html"
                          title="Xổ Số Miền Trung"
                          ><strong>Xổ Số Miền Trung</strong></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-mien.html?mien=3&amp;lanquay=5&amp;tracuu=1&amp;dai1=1&amp;dai2=2"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Miền Trung"
                          >Tần suất Miền Trung</a
                        >
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-trung"
                          title="Thống kê tần suất chi tiết xổ số Miền Trung"
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-mien.html?mien=3"
                          title="Thống kê Gan Cực Đại Xổ Số Miền Trung"
                          >GCĐ Miền Trung</a
                        >
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-mien.html?mien=3&amp;dai1=1&amp;dai2=2"
                          title="Thống Kê lô các cặp số của Xổ Số Miền Trung"
                          >Xem Loto Miền Trung</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_tinh30"
                          href="/xo-so-mien-trung/da-nang.html"
                          title="Xổ Số Đà Nẵng"
                          ><span class="icon finnish">Xổ Số Đà Nẵng</span></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=30&amp;lanquay=5&amp;tracuu=1"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Đà Nẵng"
                          >Tần suất Đà Nẵng
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=da-nang"
                          title="Thống kê tần suất chi tiết xổ số  Đà Nẵng "
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=30"
                          title="Thống kê Gan Cực Đại Xổ Số Đà Nẵng "
                          >GCĐ Đà Nẵng
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-tinh.html?tinh=30"
                          title="Thống Kê lô các cặp số của Xổ Số Đà Nẵng"
                          >Xem Loto Đà Nẵng
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          class="hottoday_tinh31"
                          href="/xo-so-mien-trung/khanh-hoa.html"
                          title="Xổ Số Khánh Hòa"
                          ><span class="icon finnish">Xổ Số Khánh Hòa</span></a
                        >
                      </td>
                      <td class="norightborder">
                        <a
                          href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=31&amp;lanquay=5&amp;tracuu=1"
                          title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Khánh Hòa"
                          >Tần suất Khánh Hòa
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=khanh-hoa"
                          title="Thống kê tần suất chi tiết xổ số  Khánh Hòa "
                          target="_blank"
                          >Chi tiết!..
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=31"
                          title="Thống kê Gan Cực Đại Xổ Số Khánh Hòa "
                          >GCĐ Khánh Hòa
                        </a>
                      </td>
                      <td>
                        <a
                          href="/thong-ke-xo-so/lo-to-tinh.html?tinh=31"
                          title="Thống Kê lô các cặp số của Xổ Số Khánh Hòa"
                          >Xem Loto Khánh Hòa
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="box_tkmd_lotodauduoi" style=" position:relative">
                <div class="clear"></div>
                <div class="box_tkdefault_xhn3l ">
                  <div class="clear"></div>
                  <div class="boxlotodefault box_tkdefault_loto">
                    <h4 class="box_tkdefault_title">
                      Bảng Thống kê "Chục - Đơn vị" trong
                      <input
                        name="tkdefault_loto_songay"
                        id="tkdefault_loto_songay"
                        type="text"
                        style="width:30px; line-height:100%; padding:0px; text-align:center; font-size:14px"
                        maxlength="4"
                        value="3"
                      />
                      ngày
                    </h4>
                    <table
                      width="100%"
                      border="0"
                      cellspacing="0"
                      cellpadding="2"
                      id="thongkexxdau"
                    >
                      <tbody>
                        <tr class="header">
                          <td
                            width="50%"
                            colspan="3"
                            align="center"
                            nowrap="nowrap"
                          >
                            <strong>Hàng chục</strong>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <strong>Số</strong>
                          </td>
                          <td
                            width="50%"
                            colspan="3"
                            align="center"
                            nowrap="nowrap"
                          >
                            <strong>Hàng đơn vị</strong>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">5 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />1
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:38%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_0">0</span>
                          </td>
                          <td nowrap="nowrap">7 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />3
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:64%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">8 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />1
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:62%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_1">1</span>
                          </td>
                          <td nowrap="nowrap">6 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/nope.gif"
                              align="absmiddle"
                              hspace="5"
                            />0
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:55%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">2 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />2
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:15%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_2">2</span>
                          </td>
                          <td nowrap="nowrap">8 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/nope.gif"
                              align="absmiddle"
                              hspace="5"
                            />0
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:73%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">5 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />1
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:38%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_3">3</span>
                          </td>
                          <td nowrap="nowrap">8 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />1
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:73%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">13 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />1
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:100%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_4">4</span>
                          </td>
                          <td nowrap="nowrap">7 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />2
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:64%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">9 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />5
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:69%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_5">5</span>
                          </td>
                          <td nowrap="nowrap">8 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />2
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:73%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">10 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />3
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:77%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_6">6</span>
                          </td>
                          <td nowrap="nowrap">9 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />2
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:82%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">12 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />3
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:92%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_7">7</span>
                          </td>
                          <td nowrap="nowrap">9 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />1
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:82%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">9 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />5
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:69%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_8">8</span>
                          </td>
                          <td nowrap="nowrap">11 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/down.gif"
                              align="absmiddle"
                              hspace="5"
                            />3
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:100%;"></div>
                          </td>
                        </tr>

                        <tr>
                          <td nowrap="nowrap">8 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/up.gif"
                              align="absmiddle"
                              hspace="5"
                            />2
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:62%;"></div>
                          </td>
                          <td align="center" nowrap="nowrap" class="so">
                            <span class="bol30 bol30_9">9</span>
                          </td>
                          <td nowrap="nowrap">8 Lần</td>
                          <td nowrap="nowrap">
                            <img
                              src="https://mlnhngoc.net/files/nope.gif"
                              align="absmiddle"
                              hspace="5"
                            />0
                          </td>
                          <td width="120" nowrap="nowrap">
                            <div class="chiso" style="width:73%;"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-center">
          <div class="conten-right bullet" style="margin-bottom: 8px;">
            <div id="datepicker"></div>
          </div>
          <div class="modulesLR moduleBrown">
            <div style="display:none;">Kết quả xổ số Hôm Nay</div>

            <div id="ketquaxosohomnay">
              <div class="title-l">
                <div class="title-r2" style="position:relative;">
                  <a class="next-day" href="#">&lt;</a>
                  <h1 style="text-align:center;">
                    Kết quả xổ số Hôm Nay<small>Thứ tư - Ngày</small
                    ><!--08/02/2023-->
                  </h1>
                  <a class="previous-day" href="#">&gt;</a>
                </div>
              </div>
              <div class="body-l">
                <div class="body-r">
                  <ul class="menu menulichxoso ">
                    <li>
                      <a
                        class="hottoday_mien"
                        href="#"
                        title="Kết quả xổ Số Miền Nam"
                        ><strong>Kết quả xổ Số Miền Nam</strong></a
                      >
                      <ul>
                        <li>
                          <a href="#" title="Kết quả xổ Số Cần Thơ"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Cần Thơ</span
                            ></a
                          >
                        </li>

                        <li>
                          <a href="#" title="Kết quả xổ Số Đồng Nai"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Đồng Nai</span
                            ></a
                          >
                        </li>

                        <li>
                          <a href="#" title="Kết quả xổ Số Sóc Trăng"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Sóc Trăng</span
                            ></a
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        class="hottoday_mien"
                        href="#"
                        title="Kết quả xổ Số Miền Bắc"
                        ><strong>Kết quả xổ Số Miền Bắc</strong></a
                      >
                      <ul>
                        <li>
                          <a href="#" title="Kết quả xổ Số Bắc Ninh"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Bắc Ninh</span
                            ></a
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        class="hottoday_mien"
                        href="#"
                        title="Kết quả xổ Số Miền Trung"
                        ><strong>Kết quả xổ Số Miền Trung</strong></a
                      >
                      <ul>
                        <li>
                          <a href="#" title="Kết quả xổ Số Đà Nẵng"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Đà Nẵng</span
                            ></a
                          >
                        </li>

                        <li>
                          <a href="#" title="Kết quả xổ Số Khánh Hòa"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Khánh Hòa</span
                            ></a
                          >
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a
                        class="hottoday_mien"
                        href="#"
                        title="Kết quả xổ Số Điện Toán"
                        ><strong>Kết quả xổ Số Điện Toán</strong></a
                      >
                      <ul>
                        <li>
                          <a href="#" title="Kết quả xổ Số 1*2*3"
                            ><span class="icon finnish"
                              >Kết quả xổ Số 1*2*3</span
                            ></a
                          >
                        </li>
                        <li>
                          <a href="#" title="Kết quả xổ Số 6X36"
                            ><span class="icon finnish"
                              >Kết quả xổ Số 6X36</span
                            ></a
                          >
                        </li>
                        <li>
                          <a href="#" title="Kết quả xổ Số Thần Tài 4"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Thần Tài 4</span
                            ></a
                          >
                        </li>
                        <li>
                          <a href="#" title="Kết quả xổ Số Mega 6/45"
                            ><span class="icon finnish"
                              >Kết quả xổ Số Mega 6/45</span
                            ></a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="conten-right xoso">
            <div class="title-r">Kết quả xổ số Hôm Nay</div>
            <ul>
              <li>
                <a class="hottoday_mien" href="#" title="Kết quả xổ Số Miền Nam"
                  ><strong>Kết quả xổ Số Miền Nam</strong></a
                >
                <ul>
                  <li>
                    <a href="#" title="Kết quả xổ Số Cà Mau"
                      ><span class="icon wait">Kết quả xổ Số Cà Mau</span></a
                    >
                  </li>

                  <li>
                    <a href="#" title="Kết quả xổ Số Đồng Tháp"
                      ><span class="icon wait">Kết quả xổ Số Đồng Tháp</span></a
                    >
                  </li>

                  <li>
                    <a href="#" title="Kết quả xổ Số TP. HCM"
                      ><span class="icon wait">Kết quả xổ Số TP. HCM</span></a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a class="hottoday_mien" href="#" title="Kết quả xổ Số Miền Bắc"
                  ><strong>Kết quả xổ Số Miền Bắc</strong></a
                >
                <ul>
                  <li>
                    <a href="#" title="Kết quả xổ Số Hà Nội"
                      ><span class="icon wait">Kết quả xổ Số Hà Nội</span></a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  class="hottoday_mien"
                  href="#"
                  title="Kết quả xổ Số Miền Trung"
                  ><strong>Kết quả xổ Số Miền Trung</strong></a
                >
                <ul>
                  <li>
                    <a href="#" title="Kết quả xổ Số Phú Yên"
                      ><span class="icon wait">Kết quả xổ Số Phú Yên</span></a
                    >
                  </li>

                  <li>
                    <a href="#" title="Kết quả xổ Số Thừa T. Huế"
                      ><span class="icon wait"
                        >Kết quả xổ Số Thừa T. Huế</span
                      ></a
                    >
                  </li>
                </ul>
              </li>
              <li>
                <a
                  class="hottoday_mien"
                  href="#"
                  title="Kết quả xổ Số Điện Toán"
                  ><strong>Kết quả xổ Số Điện Toán</strong></a
                >
                <ul>
                  <li>
                    <a href="#" title="Kết quả xổ Số 1*2*3"
                      ><span class="icon wait">Kết quả xổ Số 1*2*3</span></a
                    >
                  </li>
                  <li>
                    <a href="#" title="Kết quả xổ Số Thần Tài 4"
                      ><span class="icon wait"
                        >Kết quả xổ Số Thần Tài 4</span
                      ></a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-right">
          <div class="conten-right txt-center">
            <div class="title-r">
              <span
                ><img
                  src="https://minhngocstar.com/css/images/hot2.gif"
                  alt="hot"
                  title="hot"/></span
              ><a class="" href="#" title="Bình chọn cặp số đẹp" rel="nofollow"
                ><b class="">Bình chọn cặp số đẹp</b></a
              >
            </div>
            <div v-for="(item, index) in banner">
              <a href="#" title="Hanoi Extra" target="_blank"
                ><img :src="item.image" style="max-width: 100%;"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div
        class="main clearfix"
        style="margin-top: 15px; background-color: #001754; padding: 15px; text-align: center; color: #FFF; font-size: 120%;"
      >
        Hanoi-VISA.com
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "@/axios.ts";

export default {
  name: "Home",
  data() {
    return {
      AniDiceRandom: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: false,
        26: false,
      },
      AniDiceRandom2: false,
      dic: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
      },
      pictureUrl: "",
      first: "",
      data: [],
      banner: [],
      seconds: 0,
      countdown: "",
      nextday: "",
      selectdate: "",
      loading: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false,
        22: false,
        23: false,
        24: false,
        25: false,
        26: false,
      },
    };
  },
  created() {
    $("#datepicker").datepicker({
      onSelect: (dateText) => {
        this.getLottoByDate(dateText);
      },
    });

    this.getBackGround();
    this.getBanners();
    this.getLotto();
    // this.getLast();
  },
  methods: {
    async getBackGround() {
      const background = "";
      if (background) {
        this.pictureUrl = background.image;
      }
    },
    async getBanners() {
      this.banner = [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2F-NNnlz84HH5n2G314Bso.jpg?alt=media&token=af0d46c1-51e0-47c6-a0e9-901373d693d6",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2F-NNnm4aKHkid8F1gsyDy.jpg?alt=media&token=b617c7f9-cb42-4242-9d98-fe446af7df96",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2F-NNnm8z4n4FRtyHaSUDx.jpg?alt=media&token=d0be05ca-2061-465d-b17b-e6e29db9f26f",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2F-NNnmG6RK2l7t9JW-WTz.jpg?alt=media&token=ccf41c27-400c-4dd6-af44-af0a7215cbb3",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/lotto-landingpage.appspot.com/o/images%2F-NNnmvSfHL6MsVzcn83N.jpg?alt=media&token=1dfe5cf6-7adc-4e10-8cf1-65989aabc532",
        },
      ];
    },
    getTimeServer() {
      return moment()
        .tz("Asia/Bangkok")
        .toISOString();
    },
    formatDate(date) {
      return moment(date)
        .tz("Asia/Bangkok")
        .format("DD/MM/YYYY");
    },
    formatTime(date) {
      if (date) {
        return moment(date)
          .tz("Asia/Bangkok")
          .format("HH:mm");
      }
    },
    async getLottoByDate(date) {
      const timezone = this.getTimeServer();
      const d = date.split("/");
      let datemoment = moment(d[2] + "-" + d[0] + "-" + d[1])
        .tz("Asia/Bangkok")
        .format("YYYY-MM-DD");
      const today = moment(timezone).format("YYYY-MM-DD");

      if (
        Number(moment(timezone).format("YYYYMMDD")) <
        Number(
          moment(d[2] + "-" + d[0] + "-" + d[1])
            .tz("Asia/Bangkok")
            .format("YYYYMMDD")
        )
      ) {
        return;
      }
      if (
        datemoment === today &&
        Number(moment(timezone).format("HHmm")) < 1615
      ) {
        datemoment = moment(d[2] + "-" + d[0] + "-" + d[1])
          .tz("Asia/Bangkok")
          .add(-1, "days")
          .format("YYYY-MM-DD");
      } else {
        for (const x in this.loading) {
          this.loading[x] = false;
        }
      }
      this.selectdate = datemoment;
      const data = []; // TODO
      if (data) {
        for (const x in data) {
          this.first = data[x];
          break;
        }
      }
    },
    renderCountDown(datetime) {
      // Find the distance between now and the count down date
      // const t1 = moment().tz('Asia/Bangkok')
      // const t2 = moment(datetime).tz('Asia/Bangkok')
      // const distance = t2.diff(t1, 'seconds')
      // console.log(distance);
      const date2 = moment(datetime);
      const now = moment();
      const duration = moment.duration(date2.diff(now));
      // Time calculations for days, hours, minutes and seconds
      var hours = ("0" + duration.hours()).slice(-2);
      var minutes = ("0" + duration.minutes()).slice(-2);
      var seconds = ("0" + duration.seconds()).slice(-2);
      // Display the result in an element with id="demo"
      this.countdown = hours + ":" + minutes + ":" + seconds;

      // If the count down is finished, write some text
    },

    async getLastData() {
      const timezone = this.getTimeServer();
      // const datas = (await firebase.database().ref('/huay/3').orderByChild('datetime').startAt(moment(timezone).format('YYYY-MM-DD HH:mm')).limitToFirst(1).once('value')).val()
      await axios.get("/last-hanoi").then((response) => {
        const datas = [response.data];
        if (datas) {
          for (let index = 0; index < datas.length; index++) {
            const data = datas[index];
            this.first = data;

            if (
              data &&
              data.datetime === moment(timezone).format("YYYY-MM-DD HH:mm")
            ) {
              if (
                this.first.five !== data.five &&
                (this.selectdate === moment(timezone).format("YYYY-MM-DD") ||
                  !this.selectdate)
              ) {
                this.first = data;
                for (const x in this.loading) {
                  this.loading[x] = false;
                }
              }
            } else if (
              this.selectdate === moment(timezone).format("YYYY-MM-DD") ||
              !this.selectdate
            ) {
              const t1 = moment(timezone);
              const t2 = moment.tz(data.datetime + ":00", "Asia/Bangkok");
              const diff = t2.diff(t1, "seconds");

              // $('#clock-lottery').FlipClock(diff, {
              //   countdown: true
              // });
              this.nextday = data;
              this.renderCountDown(data.datetime);


              if (diff <= 2700) {
                for (const x in this.loading) {
                  this.loading[x] = true;
                }
                this.first.datetime = data.datetime;
              }
              if (diff <= 900) {
                this.first = data;
                // แถวสอง
                if (diff >= 870 && this.AniDiceRandom[1] === false) {
                  this.AniDiceRandom[1] = true;
                  this.AniDice(6);
                  this.AniDice(7);
                  this.AniDice(8);
                  this.AniDice(9);
                  this.AniDice(10);
                }
                if (diff <= 900) {
                  this.loading[1] = false;
                }
                if (diff <= 870) {
                  this.stopDice(6);
                }
                if (diff <= 869) {
                  this.stopDice(7);
                }
                if (diff <= 868) {
                  this.stopDice(8);
                }
                if (diff <= 867) {
                  this.stopDice(9);
                }
                if (diff <= 866) {
                  this.stopDice(10);
                }
                // 5 ตัว
                for (let i = 0; i < 8; i++) {
                  const start = 865 - i * 30;
                  const end = 835 - i * 30;
                  this.checkAniDice(
                    data,
                    diff,
                    start,
                    end,
                    11 + i,
                    2 + i,
                    2 + i,
                    5
                  );
                }
                // 4 ตัว
                for (let i = 0; i < 10; i++) {
                  const start = 625 - i * 30;
                  const end = 595 - i * 30;
                  this.checkAniDice(
                    data,
                    diff,
                    start,
                    end,
                    19 + i,
                    10 + i,
                    10 + i,
                    4
                  );
                }
                // 3 ตัว
                for (let i = 0; i < 3; i++) {
                  const start = 325 - i * 30;
                  const end = 295 - i * 30;
                  this.checkAniDice(
                    data,
                    diff,
                    start,
                    end,
                    29 + i,
                    20 + i,
                    20 + i,
                    3
                  );
                }
                // 2 ตัว
                for (let i = 0; i < 4; i++) {
                  const start = 235 - i * 30;
                  const end = 205 - i * 30;
                  this.checkAniDice(
                    data,
                    diff,
                    start,
                    end,
                    32 + i,
                    23 + i,
                    23 + i,
                    2
                  );
                }
                if (diff <= 115) {
                  this.loading[0] = false;
                }
                if (diff <= 115 && this.AniDiceRandom[0] === false) {
                  this.AniDiceRandom[0] = true;
                  this.AniDice(1);
                  this.AniDice(2);
                  this.AniDice(3);
                  this.AniDice(4);
                  this.AniDice(5);
                } else if (diff === 1) {
                  setTimeout(() => {
                    this.stopDice(1);
                  }, 1000);
                  setTimeout(() => {
                    this.stopDice(2);
                  }, 2000);
                  setTimeout(() => {
                    this.stopDice(3);
                  }, 3000);
                  setTimeout(() => {
                    this.stopDice(4);
                  }, 4000);
                  setTimeout(() => {
                    this.stopDice(5);
                  }, 5000);
                }
              }
              // แถวสาม
            }
          }
        }
      });
    },
    AniDice(dic) {
      this.dic[dic] = setInterval(this.rolldice, 100, dic);
    },
    AniDice5(dic) {
      this.dic[dic] = setInterval(this.rolldice5, 100, dic);
    },
    AniDice4(dic) {
      this.dic[dic] = setInterval(this.rolldice4, 100, dic);
    },
    AniDice3(dic) {
      this.dic[dic] = setInterval(this.rolldice3, 100, dic);
    },
    AniDice2(dic) {
      this.dic[dic] = setInterval(this.rolldice2, 100, dic);
    },

    checkAniDice(data, diff, start, end, dic, check, load, digit) {
      if (diff <= start) {
        this.loading[load] = false;
        this.first.fivearray = data.fivearray;
      }
      if (diff <= start && this.AniDiceRandom[check] === false) {
        this.AniDiceRandom[check] = true;
        if (digit === 5) {
          this.AniDice5(dic);
        } else if (digit === 4) {
          this.AniDice4(dic);
        } else if (digit === 3) {
          this.AniDice3(dic);
        } else if (digit === 2) {
          this.AniDice2(dic);
        }
      }
      if (diff <= end) {
        this.stopDice(dic);
      }
    },
    stopDice(dic) {

      clearInterval(this.dic[dic]);
      if (dic == 1) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five.charAt(0);
      } else if (dic == 2) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five.charAt(1);
      } else if (dic == 3) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five.charAt(2);
      } else if (dic == 4) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five.charAt(3);
      } else if (dic == 5) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five.charAt(4);
      } else if (dic == 6) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five2.charAt(0);
      } else if (dic == 7) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five2.charAt(1);
      } else if (dic == 8) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five2.charAt(2);
      } else if (dic == 9) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five2.charAt(3);
      } else if (dic == 10) {
        document.getElementById(
          "dice" + dic
        ).innerHTML = this.first.five2.charAt(4);
      } else if (dic >= 11 && dic <= 18) {
        document.getElementById("dice" + dic).innerHTML = this.first.fivearray[
          Number(dic) - 11
        ];
      } else if (dic >= 19 && dic <= 28) {
        document.getElementById("dice" + dic).innerHTML = this.first.fourarray[
          Number(dic) - 19
        ];
      } else if (dic >= 29 && dic <= 31) {
        document.getElementById("dice" + dic).innerHTML = this.first.threearray[
          Number(dic) - 29
        ];
      } else if (dic >= 32 && dic <= 35) {
        document.getElementById("dice" + dic).innerHTML = this.first.twoarry[
          Number(dic) - 32
        ];
      }
    },

    async getLotto() {
      await axios.get("/list-v2/3").then((response) => {
        this.data = response.data;
        setInterval(this.getLastData, 1000);
      });
    },
    async getLast() {
      // await axios
      //   .get('/last/3')
      //   .then((response) => {
      //     this.first = response.data
      //   })
    },
  },
};
</script>
<style scoped>
span.bol30 {
  font-family: Arial, Helvetica, sans-serif;
}
span.bol30_0 {
  background-position: left 0;
}
span.bol30_1 {
  background-position: left -32px;
}
span.bol30_2 {
  background-position: left -64px;
}
span.bol30_3 {
  background-position: left -97px;
}
span.bol30_4 {
  background-position: left -130px;
}
span.bol30_5 {
  background-position: left -162px;
}
span.bol30_6 {
  background-position: left -194px;
}
span.bol30_7 {
  background-position: left -226px;
}
span.bol30_8 {
  background-position: left -259px;
}
span.bol30_9 {
  background-position: left -291px;
}
span.bol30 {
  background-image: url(https://mlnhngoc.net/template/2016/images/glass-balls-30px.png);
  background-repeat: no-repeat;
  font-size: 16px;
  height: 32px;
  width: 30px;
  display: inline-block;
  border: 0;
  position: static;
  text-align: center;
  color: #000;
}
div.box_tkdefault_loto h4.box_tkdefault_title {
  background: url(https://mlnhngoc.net/template/2016/images/rand.png) top #999;
  line-height: 25px;
  padding-left: 10px;
  border: 1px solid #333;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border-radius: 5px 5px 0 0;
  margin-bottom: 0px;
}
.box_tkdefault_xhn3l .box_tkdefault_title {
  border-bottom: 1px solid #e9e9e9;
  font-size: 12px;
}
div.box_tkdefault_loto table {
  border: 1px solid #333;
  background-color: #fff;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
user agent stylesheet table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 2px;
}
#dnw-main,
table td {
  font-size: 12px;
  line-height: 1.5;
}

#dnw-main,
#topmenu_mien_home li a,
.dnw-sheet-body,
.info_slide *,
.sodauduoi_miennam .kq,
input,
select,
table td,
textarea {
  font-family: Arial, Helvetica, sans-serif;
}
div.box_tkdefault_loto table tr.header td {
  background: #ccc;
  height: 20px;
  line-height: 20px;
}
div.box_tkdefault_loto table td {
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 2px;
}
.dnw-content-layout td {
  vertical-align: top;
}
div.box_tkdefault_loto table td {
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 2px;
}
#thongkexxdau .chiso {
  float: left;
  background: url(https://mlnhngoc.net/images/rand.png) #a60062;
  height: 10px;
}
.dnw-content-layout .main div,
.dnw-content-layout .main table {
  max-width: 520px;
}
.box_tkdefault_xhn3l .chiso,
ul.menumember {
  margin-top: 10px;
}
.modulesLR.moduleBrown .title-l {
  background: #eee;
  color: #000;
  border: 1px solid #9e9e9e;
  border-top: 0;
}
.modulesLR .title-l,
.modulesLR .title-l_red {
  border-radius: 5px 5px 0 0;
  margin: -5px -1px 0;
}
#ketquaxosohomnay {
  position: relative;
  min-height: 100px;
}
a.next-day {
  position: absolute;
  top: 7px;
  left: 3px;
  border: 1px solid #a1a1a1;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  color: #a1a1a1;
}
a.previous-day {
  position: absolute;
  top: 7px;
  right: 3px;
  border: 1px solid #a1a1a1;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  color: #a1a1a1;
}
.modulesLR.moduleBrown .title-r2 h1,
.modulesLR.moduleBrown .title-r2 h1 a {
  color: #000;
}
.modulesLR .title-r2 h1 {
  padding: 5px 10px;
}
.modulesLR .title-l,
.modulesLR .title-l_red {
  border-radius: 5px 5px 0 0;
  margin: -5px -1px 0;
}
.modulesLR .title-r2 h1,
.modulesLR .title-r2 h1 a {
  color: #fff;
  font-size: 12px;
  text-align: left;
  font-weight: 700;
}
.modulesLR.moduleBrown {
  background: #f3f3f3;
  border: 1px solid #9e9e9e;
}
.modulesLR {
  display: block;
  border: 1px solid #eee;
  border-radius: 5px;
  background: #f9f9f9;
  margin: 0;
  padding: 5px 0;
}
.box_kqxs div img,
.box_kqxs div.Menutool img,
.box_kqxs td,
.dnw-content-layout .box_kqxs td,
.modulesLR .title-r2,
div.Menutool img,
input,
select,
span.bol30,
textarea {
  vertical-align: middle;
}
ul.menu.menulichxoso li a {
  background: url(https://www.minhngoc.net.vn/template/2016/images/arrow_down_gold.png)
    left center no-repeat;
  padding-left: 15px;
}
ul.menu li a.hottoday_mien,
ul.menu2 li a.hottoday_tinh7 {
  font-weight: 400;
}
ul.menu li a {
  background: url(https://www.minhngoc.net.vn/template/2016/images/arrow_1.gif)
    left center no-repeat;
  padding: 3px 0 3px 10px;
}
#hottoday .finnish,
ul.menu.menu .finnish,
ul.menu2 .finnish {
  background: url(https://www.minhngoc.net.vn/template/2016/images/ok_daxosoxong.png)
    right center no-repeat;
}
#hottoday .icon,
ul.menu .icon,
ul.menu2 .icon {
  display: block;
}

.boxdauduoimien td,
.dauduoi td,
ul.menu li ul li a,
ul.menu2 li ul li a {
  font-family: Arial, Helvetica, sans-serif;
}
ul.menu li ul li a,
ul.menu2 li ul li a {
  text-decoration: none;
  font-size: 11px;
  color: #333;
  display: block;
  font-weight: 400;
  white-space: nowrap;
  padding: 3px 0;
}
ul.menu li a,
ul.menu2 li a {
  font-size: 11px;
  color: #333;
  display: block;
  font-weight: 700;
  white-space: nowrap;
}
ul.menu li a,
ul.menu2 li a {
  font-size: 11px;
  color: #333;
  display: block;
  font-weight: 700;
  white-space: nowrap;
}
ul.menu.menulichxoso li ul li a {
  background: url(https://www.minhngoc.net.vn/template/2016/images/arrow_1.gif)
    5px center no-repeat;
  padding-left: 15px;
}
#hottoday .border {
  border: none;
}
#box_tracuukqxs input,
#box_tracuukqxs select,
#box_tracuukqxs td,
#hottoday * {
  font-size: 11px;
}
.dnw-content-layout .main div,
.dnw-content-layout .main table {
  max-width: 520px;
}
</style>
